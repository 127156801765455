.suspendButtonTs {
  position: fixed;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 5px rgba(#000000, 0.4);
  user-select: none;
  z-index: 1;
  touch-action: 'none!important';
}

.suspendButtonTs img {
  width: 100%;
  height: 100%;
  user-select: none;
  position: sticky;
  z-index: -1;
}

.suspendButtonTs-animate {
  transition-duration: 0.1s;
}
